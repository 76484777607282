@import '../../global.scss';

/* mobile */

.container {
    @include container;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: column;
}

.infoBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.navMax {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0 10px 0;
    color: $color-text;
    & li {
        & a {
            color: $color-text;
            font-size: $font-size-small;
        }
    }
}

.authorBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: $font-size-small;
    margin: 7px 0 0 0;
    & p  {
        color: $color-text;
    }
    & a {
        margin: 0 5px;
        color: $border-menu;
    }
}

.donat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & img {
        position: static;
        width: 100px;
    }
}

.text {
    color: $color-text;
    text-align: center;
    font-size: $font-size-small;
    & a {
        color: $border-menu;
    }
}

.copyrightBox {
    display: flex;
    align-items: center;
}

.copyrightImg {
    fill: $text-onBlack;
}

.socialIcons {
    width: 20px;
    height: 20px;
    fill: $text-onBlack;
    margin: 0 2px 0 0;
}

.socialIcons:last-child {
    margin: 0 0 0 2px;
}

.copyrightText {
    font-size: $font-size-little;
    color: rgba(255, 250, 250, 0.8);
    margin: 0 2px;
}

.socialLink {
    display: flex;
    align-items: center;
    padding: 5px;
}

@media (min-width: 480px) {
    .container {
        margin-left: 10%;
        margin-right: 10%;
    }

    .authorBox {
        & a {
            font-size: $font-size-little;
        }
    }

    .text {
        font-size: $font-size-little;
    }
}

@media (min-width: 768px) {
    .container {
        position: relative;
        margin: 0;
    }
    .infoBox {
        flex-direction: row;
    }

    .donat {
        margin: 0 0 10px 0;
        & img {
            position: absolute;
            bottom: 2px;
            right: 120px;
        }
    }
    
    .navMax {
        & li {
            & a {
                font-size: $font-size-little;
            }
        }
    }

    .authorBox {
        margin: 0;
    }

    .socialLink {
        padding: 0;
    }
}

@media (min-width: 1024px) {
    .container {
        margin: 0 2%;
    }

    .donat img {
        bottom: 5px;
        right: 150px;
    }
}

@media (min-width: 1440px) {
    .container {
        margin: 0 10%;
    }

    .donat img {
        bottom: 10px;
    }
}