@import '../../global.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  & p {
    margin: 0 10px 0 10px;
    cursor: pointer;
    &:last-child {
      margin: 0;
    }
  }

  .activNumber {
    color: red;
    font-weight: 700;
    text-decoration: underline;
  }

  .arrow {
    cursor: pointer;
    margin: 0 5px 0 5px;
  }
}