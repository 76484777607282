@import '../../../global.scss';

/* mobile */

.container {
    flex: 0 1 auto;
}

.title {
    font-size: $font-size-average;
    &::first-letter {
        text-transform: uppercase;
    }
}

.search {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    background: #d1d1d1;
    border-radius: 20px;
    margin: 5px 0;
}

.searchIcon img{
    width: 20px;
    height: 20px;
    color: beige;
    flex: 0 0 40px;
}

.searchInput {
    border: none;
    flex: 1 1 auto;
    font-size: $font-size-normal;
    background: #d1d1d1;
    padding: 5px;
    height: 20px;
    &:focus {
        border: none;
    }
}
/* tablets */

@media (min-width: 768px) {
    // .container {
    //     display: flex;
    //     flex-direction: row;
    // }
}