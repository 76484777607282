@import '../../global.scss';


.articleTitle {
  line-height: 1.5;
  text-transform: none;
}

@media (min-width: 768px) {
    
    .mobile {
        display: none;
    }
}