@import '../../global.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

input {
    @include input;
}

button {
    @include button;
}

.email, 
.password,
.pin {
    & label {
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        width: 100%;
        background-color: $border-menu;
        color: $background-white;
        padding: 5px;
    }
}

.password {
    position: relative;
    & label {
        top: 30px;
    }
}

.visibil {
    height: 20px;
    width: auto;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.checkbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px 0 0 0;
    & label {
        font-size: $font-size-small;
        margin: 0 0 0 2px;
    }
}

.inputBlock {
    display: flex;
    flex-direction: column;
}

.reset {
    @include button;
    margin-top: 0;
}

.disabled {
    @include button;
    background-color: $color-background-light;
    box-shadow: 2px 2px 5px $color-background;
    color: $color-link;
    cursor: default;
}