@import '../../global.scss';

/* mobile */

.container {
    @include container;
    background-color: $background-white;
    flex: 1 1 auto;
    margin: 70px 0 0 0;
}

.warningBox {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & p {
        margin: 0 0 10px 0;
    }
}

.linkBox:nth-child(1) {
    & a {
        padding: 0 5px 0 0;
    }
}

.desctop {
    display: none;
}

.contentBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1%;
}
/* tablets */

@media (min-width: 768px) {
    .container {
        margin-top: 75px;
    }
}

@media (min-width: 1024px) {
    .container {
        margin: 75px 2% 0;
    }
    .desctop {
        display: block;
        flex: 1 1 45%;
    }
}

@media (min-width: 1280px) {
    .container {
        margin: 80px 10% 0;
    }
    // .desctop {
    //     flex: 1 1 40%;
    // }
}