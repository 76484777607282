@import '../../../global.scss';

/* mobile */

.container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.titleBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & img {
        height: 20px;
        margin: 0 10px 0 0;
    }
}

.desktop {
    display: none;
}

.faqMenuBox {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.faqTitle {
    @include subtitle;
    text-align: center;
    text-transform: none;
}

.articleTitle {
    line-height: 1.5;
    text-transform: none;
}

.faqMenu li{
    line-height: 1.5;
    }

.faqMenu li::after {
    content: '';
}

.faqMenu a {
    text-transform: capitalize;
}

.image {
    display: none;
}

.image img{
    padding: 15px;
    width: 80%;
}

.plus:hover {
    cursor: pointer;
}
/* tablets */

@media (min-width: 768px) {
    .faqMenuBox {
        flex: 0 0 100%;
    }

    .image img{
        align-items: center;
        padding: 15px;
        width: 90%;
    }

    .desktop {
        display: block;
    }
    
    .mobile {
        display: none;
    }
}

@media (min-width: 1440px) {
    .image {
        flex: 0 0 40%;
        display: flex;
        justify-content: center;
    }

    .faqMenuBox {
        flex: 0 0 60%;
    }

    .image img{
        align-items: center;
        padding: 15px;
        width: 90%;
    }
}