@import '../../../../../../global.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid $color-border;
  padding: 15px 0 0 0;
}

.title {
  line-height: 1.2;
  font-size: $font-size-average;
}

.articleBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.avatar {
  padding: 5px;
  & img {
    width: 30px;
    border-radius: 50%;
  }
}

.editBoxMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 0;
}

.editBox {
  display: none;
}

.info {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 5px;
  border: 1px solid $color-background-light;
}

.avatarEditMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.user,
.userBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  & h4, p {
    font-size: $font-size-little;
  }
}

.userBox {
  flex: 1 1 auto;
  justify-content: start;
  flex-wrap: wrap;
  border: 1px solid $color-background-light;
}

.user {
  justify-content: space-between;
  align-items: center;
}

.edit {
  margin: 0 10px;
}

.text {
  flex: 1 1 100%;
  min-height: 75px;
  & p {
    color: $color-text;
    line-height: 1.5;
    margin: 10px 0;
  }
}

.rate,
.like,
.disLike,
.userlike,
.userdislike,
.disabled,
.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rate {
  margin: 10px 0 0 0;
}

.like,
.disLike,
.edit,
.update,
.deleteCom,
.disabled {
  cursor: pointer;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.like,
.disLike,
.disabled {
  border: 1px solid $color-border;
  border-radius: 10px;
  padding: 2px 5px;
  margin: 0 15px 0 0;
  background-color: $color-title;
  color: $color-text-button;
  font-weight: $font-weight-bolder;
  & p {
    padding: 0 0 0 5px;
  }
}

.userlike {
  margin: 0 10px;
}

.login,
.date,
.time {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $color-text;
  & h4 {
    margin: 0 5px 0 0;
  }
}

.date {
  margin: 0 10px;
}

.likeImg {
  fill: $color-text-button;
}

.disabled {
  background-color: $background-white;
  cursor: default;
}

@media (min-width: 480px) {
  .articleBox {
    flex-direction: row;
  }

  .avatar {
    & img {
      width: 50px;
    }
  }
  
  .user,
  .userBox {
    & h4, p {
      font-size: $font-size-normal;
    }
  }

  .avatarEditMobile {
    flex-direction: column;
  }

  .editBoxMobile {
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
  }
}

@media (min-width: 768px) {
  .editBoxMobile {
    display: none;
  }
  
  .articleBox {
    flex-direction: row;
  }

  .editBox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

