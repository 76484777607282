@import '../../../../../../global.scss';

.container {
    flex: 1 1 auto;
    width: 100%;
    margin: 10px 0;
}

.loginMessageBox {
    text-align: center;
    color: $color-text;
    margin: 0 0 10px 0;
    & span {
        padding: 0 5px;
    }
}

.commentBox {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    & button {
        margin-left: auto;
    }
}

textarea {
    background-color: $color-background-light;
    border-radius: 5px;
    flex: 1 1 100%;
    min-height: 100px;
    padding: 5px;
    color: $color-text;
    &:focus {
        border: 1px solid $color-border;
        background-color: $background-white;
    }
}

.more {
    text-align: center;
    margin: 10px 0 0 0;
}

.prevention {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & p {
        margin: 5px 0;
        &:nth-child(2) {
            margin: 0 0 10px 0;
        }
    }
    & input {
        margin: 10px 0 0 0;
    }
}

.preventionWarning {
    color: $border-menu;
    padding: 5px;
    border: 2px dashed $border-menu;
    text-align: center;
}

.svg {
    fill: $border-menu;
}

.disabled {
    background-color: $color-background-light;
    box-shadow: 2px 2px 5px $color-background;
    color: $color-link;
    cursor: default;
}

.desktop {
    display: none;
}

@media (min-width: 768px) {
    .desktop {
        display: block;
    }
}