@import '../../../global.scss';

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    @include subtitle;
}

.text {
    @include text;
    text-align: center;
    margin: 0 0 5px 0;
}

.link {
    @include link;
    text-align: center;
}

.disabled {
    background-color: $color-background-light;
    box-shadow: 2px 2px 5px $color-background;
    color: $color-link;
    cursor: default;
}