@import '../../global.scss';

.container {
  display: flex;
  flex-direction: column;
  & p {
    line-height: 1.5;
    color: $color-text;
    margin: 0 0 10px 0;
  }
  & h3 {
    font-size: 18px;
  }
}

.title {
  text-align: center;
}