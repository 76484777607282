@import '../../global.scss';

.container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title,
.titleLogin {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.title {
    margin-top: 20px;
}

.titleLogin {
    align-items: center;
    & h2 {
        font-size: $font-size-average;
    }
    & p {
        font-size: $font-size-average;
    }
}

.nameLogin {
    margin: 0 0 0 5px;
    font-size: $font-size-average;
    color: $border-menu;
}

.titleLogin {
    position: relative;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
}

.info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.photo {
    display: none;
}

.photoMobile {
    position: relative;
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    border-radius: 50%;
    margin: 0 25px 0 5px;
    & img {
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }
}

.delete {
    position: absolute;
    top: -15px;
    right: -15px;
    border-color: $border-menu;
    cursor: pointer;
}

.editBlock {
    position: absolute;
    top: -25px;
    right: -25px;
    border-color: $border-menu;
    z-index: 1;
}

.visibil {
    width: 25px;
    cursor: pointer;
}

input[type="file"] {
    display: none;
}

.file {
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: transparent;
}

.warning,
.warningAvatar,
.message,
.editMessage,
.warningphotoMobile {
    position: absolute;
    top: 85px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: max-content;
    padding: 15px;
    color: white;
    text-align: center;
    background-color: $color-text;
    border-radius: 5px;
    z-index: 1;
    & > p {
        margin: 0 0 5px 0;
    }
}

.warningAvatar {
    top: 140px;
}

.warningphotoMobile {
    left: 100%;
}

.logoutBlock,
.deleteBlock {
    position: relative;
    cursor: pointer;
}

.deleteBlock {
    display: flex;
    justify-content: flex-end;
    margin: 5px 0 0 0;
}


.editMessage,
.logoutMessage,
.deleteMessage {
    top: 10px;
    left: -40px;
    padding: 5px;
    background-color: $border-menu;
    color: white;
    border-radius: 0;
}

.deleteIcon {
    position: relative;
}

.logoutMessage,
.deleteMessage {
    position: absolute;
    top: 0;
    left: -75px;
}

.message {
    top: 70px;
    padding: 5px;
    background-color: $border-menu;
    border-radius: 0;
}

.border {
    position: relative;
    background-color: $color-text-button;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgb(92, 92, 92);
    margin: 30px;
}

.borderBold,
.loginBlock,
.emailBlock,
.passwordBlock {
    border: 2px dashed $border-menu;
    margin: 3px;
    border-radius: 5px;
}

.loginBlock,
.edit,
.visibility {
    cursor: pointer;
}

.block {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    color: $border-menu;
    & h4 {
        margin: 0 5px 0 0;
    }
}

.prevention {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & p {
        margin: 5px 0;
        &:nth-child(2) {
            margin: 0 0 10px 0;
        }
    }
    & input {
        margin: 10px 0 0 0;
    }
}

.preventionWarning {
    color: $border-menu;
    padding: 5px;
    border: 2px dashed $border-menu;
    text-align: center;
}

.svg {
    fill: $border-menu;
}

.warningSvg {
    fill: white;
}

.line {
    height: 10px;
    width: 100%;
    color: black;
    border-color: black;
}

.disabled {
    background-color: $color-background-light;
    box-shadow: 2px 2px 5px $color-background;
    color: $color-link;
    cursor: default;
}

.listComments {
    flex: 1 1 auto;
    text-align: center;
    color: $color-text;
}

.more {
    text-align: center;
    margin: 10px 0 0 0;
}

.desktop {
    display: none;
}

@media (min-width: 768px) {
    .photo {
        position: relative;
        width: inherit;
        height: inherit;
        display: flex;
        align-items: center;
        border-radius: 50%;
        & img {
            border-radius: 50%;
            width: 50px;
            height: 50px;
        }
    }

    .photoMobile {
        display: none;
    }

    .delete {
        top: 10px;
    }

    .desktop {
        display: block;
    }
}