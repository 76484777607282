@import '../../../../global.scss';

/* mobile */
.container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.searchText {
    text-align: center;
}

.hide {
    display: none;
}

.desktop {
    display: none;
}

.articleTitle {
    line-height: 1.5;
    text-transform: none;
}

.searchTitle {
    @include subtitle;
    text-align: center;
    text-transform: none;
}
/* tablets */

@media (min-width: 768px) {
    .desktop {
        display: block;
    }
}