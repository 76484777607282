@import '../../../global.scss';

/* mobile */
.container {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & h3:hover {
        color: $color-link-hover;
    }
    & h2 {
        text-align: center;
        margin: 0 0 20px 0;
    }
}

.categoryTitle {
    @include subtitle;
    text-align: center;
    text-transform: none;
}

.desktop {
    display: none;
}

/* tablets */

@media (min-width: 768px) {

    .desktop {
        display: block;
    }
}