@import '../../global.scss';

/* mobile */

.container {
    @include container;
    align-items: center;
    justify-content: space-between;
    margin: 0 1%;
}

.titleSite {
    @include logo;
}

.navigation {
    flex: 1 0 auto;
}

.lang {
    display: flex;
    justify-content: space-between;
}

.account {
    margin: 0 0 0 5px;
    fill: $text-onBlack;
}


.avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 0 0 5px;
}

li::after {
    content: '|';
    padding: 0 5px;
    font-weight: 700;
}

li:last-child::after {
    content: '';
    padding: 0;
}

.login {
    display: flex;
    margin: 0 0 0 10px;
    & li {
        text-transform: lowercase;
    }
}

/* tablets */

@media (min-width: 768px) {

    .titleSite {
        font-size: $font-size-average;
    }
}

@media (min-width: 1024px) {
    .container {
        margin: 0 2%;
    }
}

@media (min-width: 1280px) {
    .container {
        margin: 0 10%;
    }
}