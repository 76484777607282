@import '../../../global.scss';

/* mobile */

.container {
  margin: 10px 5%;
  & a {
    color: rgba(255, 250, 250, 0.8);
    font-size: $font-size-little;
    &:nth-child(1)::after {
      content: " | ";
    }
  }
}

.nav {
  display: flex;
}

.footerMenu {
  @include mainmenu;
  display: inline-block;
}

@media (min-width: 480px) {
  .container {
    font-size: $font-size-normal;
  }
}