@import '../../../../global.scss';

.container {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & h2 {
    @include subtitle;
    margin: 0 0 15px 0;
    text-align: center;
    text-transform: none;
  }
  & ul>li {
    margin-left: 20px;
    list-style-position: inside;
  }
}

.textBox {
  flex: 1 1 auto;
}

.text {
    font-size: $font-size-normal;
    color: $color-text;
    line-height: 1.5;
    margin: 0 0 10px 0;
}

.containerImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  & img {
    max-width: 90%;
    height: auto;
  }
  & span {
    font-size: 10px;
    color: gray;
  }
}

.likeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0 0;
  & h4 {
    color: $color-text;
  }
}

.likes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  & button {
    @include button;
    min-width: 100px;
    width: auto;
    & span {
      border: 1px solid $color-text;
      border-radius: 5px;
      padding: 2px 5px;
      margin-left: 5px;
    }
  }
}

.hideMessage,
.showMessage {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: rgb(206, 193, 193);
  padding: 10px;
  top: -100px;
  animation: hideMessage 1s linear forwards;
}

@keyframes hideMessage {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.showMessage {
  animation: showMessage 1s linear forwards;
}

@keyframes showMessage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.btnDisabled {
		background-color: $color-background-light;
		box-shadow: 2px 2px 5px $color-background;
		color: $color-link;
    cursor: auto;
}