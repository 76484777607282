@import '../../../global.scss';

/* mobile */

.container {
  margin: 10px 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.menuDesctop {
  @include mainmenu;
  display: none;
}

.mobileMenu {
  display: flex;
}

@media (min-width: 1024px) {
  .container {
    justify-content: flex-start;
  }

.menuDesctop {
  display: inline-block;
  margin: 0 10px;
  &:first-child {
  margin: 0 10px 0 0;
  }
  &:last-child {
    margin: 0 0 0 10px;
  }
}

  .mobileMenu {
    display: none;
  }
}