@import '../../../global.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 0 0;
  background-color: rgba(255, 255, 255, 0.25);
}

video {
  width: 100%;
}

.wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
}

.tagline {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.wrapper:hover .text {
  transform: translateY(0%);
}

.text {
  position: absolute;
  top: 0;
  right: 3px;
  transform: translateY(-150%);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  padding: 1px;
  background-color: $border-menu;
  border: thick double $border-menu;
  border-radius: 5px;
  &:hover {
    background-color: $background-white;
    & a {
      color: $border-menu;
    }
  }
  & a {
    color: $background-white;
  }
  z-index: 1;
}

.wrapper:hover .controls {
  transform: translateY(0%);
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 0;
  padding: 5px;
  width: 100%;
  max-width: 500px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateY(150%);
  transition: all 0.3s ease-in-out;
  & button {
    margin: 0;
  }
}

.actions button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

input[type="range"] {
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  cursor: pointer;
}

input[type="range"]::-moz-range-progress {
  cursor: pointer;
}

.velocity {
  appearance: none;
  background: none;
  outline: none;
  border: none;
  text-align: center;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
}

.muteBtn, 
.screenBtn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .tagline {
    font-size: $font-size-normal;
  }
}

@media (min-width: 1440px) {
  .tagline {
    font-size: $font-size-average;
  }
}