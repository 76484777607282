@import '../../global.scss';

/* mobile */
.overlay {
    opacity: 0;
    z-index: -1;
}

.overlay.open {
    opacity: 1;
    z-index: 1;
    animation: show .5s linear forwards;
}

.window {
    opacity: 0;
    z-index: -1;
}

.window.open {
    opacity: 1;
    z-index: 1;
    animation: show 0.5s linear forwards;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-background-opacity;
    display: flex;
    align-items: center;
    justify-content: center;
}

.window {
    width: fit-content;
    padding: 2%;
    background-color: $color-background;
    position: relative;
}

.close {
    font-size: $font-size-average;
    color: $color-background-light;
    position: absolute;
    top: -25px;
    right: -20px;
    padding: 0 2px;
    border: 2px solid $color-background-light;
    border-radius: 30%;
    cursor: pointer;
}

@keyframes hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }