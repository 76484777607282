@import '../../global.scss';

/* mobile */
.container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $border-menu;
    z-index: 1;
}

.element {
    position: absolute;
    top: calc(50% - 78px);
    left: calc(50% - 78px);
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

@keyframes loding {
    0% {
      top: 96px;
      left: 96px;
      width: 0;
      height: 0;
      opacity: 1;
    }

    100% {
      top: 18px;
      left: 18px;
      width: 156px;
      height: 156px;
      opacity: 0;
    }
  }

  .loding div {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: loding 1s cubic-bezier(0,0.2,0.8,1) infinite;
  }
  
  .loding div:nth-child(1) {
    border-color: #e9e10c;
    animation-delay: 0s;
  }

  .loding div:nth-child(2) {
    border-color: #469bf0;
    animation-delay: -0.5s;
  }

  .loding {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }

  .loding div { 
    box-sizing: content-box; 
}