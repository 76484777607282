@import '../../../global.scss';

/* mobile */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: red;
    & h2 {
        font-size: $font-size-normal;
        text-align: center;
        color: white;
    }
}

.imagesBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & img {
        width: 50px;
    }
}

@media (min-width: 768px) {
    .imagesBox img {
        width: 75px;
    }

    .container h2 {
        font-size: $font-size-normal;
    }
}

@media (min-width: 1024px) {
    .imagesBox img {
        width: 100px;
    }
}

@media (min-width: 1440px) {
    .container h2 {
        font-size: $font-size-big;
    }

    .imagesBox img {
        width: 120px;
    }
}

@media (min-width: 1600px) {
    .imagesBox img {
        width: 130px;
    }
}