@import '../../../global.scss';

/* mobile */

.navbar {
    width: 100%;
    flex: 0 0 25%;
    //padding: 0 5px 0 0;
}

.menu li{
    line-height: 1.5;
}

.link::after {
    content: '';
}

.link {
    @include menu;
}

.activ {
    background-color: $border-menu;
}
/* tablets */

@media (min-width: 768px) {
    .navbar {
        flex: 0 0 30%;
    }
}

@media (min-width: 1024px) {}

@media (min-width: 1440px) {
    .navbar {
        flex: 0 0 25%;
    }
}