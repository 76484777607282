@import '../../global.scss';

/* mobile */

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    background: rgb(177, 177, 177, 0.5);
    position: relative;
}

.header {
    position: fixed;
    width: 100%;
    background-color: black;
    border-bottom: 3px double #FF420E;
    z-index: 100;
}

.content {
    margin-top: 90px;
    flex: 1 1 auto;
}

.footer {
    background-color: black;
    border-top: 3px double #FF420E;
}