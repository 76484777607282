@import '../../../../global.scss';

/* mobile */

.cont {
  display: inline-block;
  width: inherit;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 25px;
  height: 3px;
  background-color: red;
  margin: 5px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.menuBoxHide {
  height: 200px;
  position: absolute;
  top: -500px;
  left: 0;
  right: 0;
  background-color: aquamarine;
  animation: menuHide 0.5s ease-in 0s 1 normal both running;
}

.menuBox {
  height: auto;
  position: absolute;
  top: 104%;
  left: 0;
  right: 0;
  animation: menuVisible 0.5s ease-in 0s 1 normal both running;
}

@keyframes menuVisible {
  from {
    top: -500px;
  }

  to {
    top: 104%;
  }
}

@keyframes menuHide {
  from {
    top: 70px;
  }

  to {
    top: -500px;
  }
}

@media (min-width: 480px) {
  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    margin: 6px 0;
  }
}