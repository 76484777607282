@import '../../../global.scss';

/* mobile */

.activeLang {
    position: relative;
    cursor: pointer;
    &:hover .list {
        opacity: 1;
        transition: all 1s;
    }
    & img {
        width: 20px;
    }
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    left: 0;
    opacity: 0;
    z-index: 1;
}

.img:after {
    content: "";
}

.img img {
    width: 20px;
    cursor: pointer;
}
