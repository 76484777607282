@import '../../../global.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    @include subtitle;
    //margin: 0 0 5px 0;
}

.text {
    @include text;
    text-align: center;
}

.link {
    @include link;
}

.background {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-background-opacity;
}

.hideBackground {
  animation: hideMessage 1s linear forwards;
}

.hideMessage,
.showMessage {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: 1.5;
    background-color: $border-menu;
    color: $background-white;
    padding: 10px;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    animation: hideMessage 1s linear forwards;
  }
  
  @keyframes hideMessage {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .showMessage {
    animation: showMessage 1s linear forwards;
  }
  
  @keyframes showMessage {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
